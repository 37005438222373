import React, { Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../Hooks';
import Analytics from 'analytics';
import { AnalyticsProvider } from 'use-analytics';
import { analyticsPlugin } from '../Utils/Analytics';
import NotFound from './Admin/NotFound/NotFound';
import i18n from '../Services/I18n';
import { getSafeSettings } from '../Store/Settings';
import { fetchSettings } from '../Store/ShopSettings';
import { getAllTemplates } from '../Store/Templates';
import AuthenticationVerificationPage from './Login/AuthenticationVerificationPage';
import { reconnectAction } from '../Store/UserAuth';
import { ISettings } from '@abstract/abstractwebcommon-shared/interfaces/logoFavSetting';
import Header from '@abstract/abstractwebcommon-client/Header';
import { getFaviconURL } from '../Services/UserSetting';
import { load } from '../Services/Api';
import { BASE_API_URL } from '../Config';
import ShopPage from './Shop/ShopPage';
import ProductPage from './Shop/ProductPage';
import TransactionSuccessPage from './Shop/TransactionSuccess/TransactionSuccessPage';
import CheckoutPage from './Shop/Checkout/CheckoutPage';
import PublicRoute from './PublicRoute';
import { IAuth } from '@abstract/abstractwebcommon-shared/interfaces/auth';
import { ErrorHandler } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import {
  AlertToast,
  showToast
} from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import ConfigurationSettingsPage from './Login/ConfigurationSettingsPage';
import ClientPanel from './Client/ClientPanel';
import AdminPanel from './Admin/AdminPanel';
import ScrollToTop from '@abstract/abstractwebcommon-client/ScrollToTop';
import {
  updateUserLanguageSettingsModeStateAction,
  updateUserThemeModeStateAction
} from '../Store/UserAPI';
import {
  getUserAPIState,
  IUserAPIState
} from '@abstract/abstractwebcommon-client/store/UserAPISlice';
import { useTranslation } from 'react-i18next';
import { changeUserTheme } from '@abstract/abstractwebcommon-client/utils/themeModeUtils';
import { customLocalStorageClearUtil } from '../Utils/localStorageUtils';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import {
  SharedCommomRouteName,
  SharedMainRouteName
} from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { RouteName } from 'src/Utils/routesNames';
import { changeUserLanguage } from '@abstract/abstractwebcommon-client/utils/LanguageSettingsModeUtils';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import TopBar from '@abstract/abstractwebcommon-client/TopBar';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import { updateUserDetailsStateAction } from '../Store/Users';

const adminRoutes = SharedMainRouteName.adminRoute;
const clientRoutes = SharedMainRouteName.clientRoute;

const Main = () => {
  /* Initialize analytics */
  const analytics = Analytics({
    app: 'instaLOD-ecommerce',
    plugins: [analyticsPlugin()]
  });

  const translation = useTranslation().t;
  const dispatch = useDispatch();
  const shopSettings: IShopSettings = useAppSelector(
    (state) => state.shopSettings.list
  );
  const settings: ISettings = useAppSelector((state) => state.settings);
  const templates = useAppSelector((state) => state.templates);
  const authState: IAuth = useAppSelector((state) => state.userAuth);
  const [isAppReconnecting, setAppReconnecting] = useState<boolean>(true);
  const [favouriteIconX180, setFavouriteIconX180] = useState<string>('');
  const [favouriteIconX32, setFavouriteIconX32] = useState<string>('');
  const [favouriteIconX16, setFavouriteIconX16] = useState<string>('');
  const userAPIState: IUserAPIState = useSelector(getUserAPIState);

  const isSettingsLoaded =
    Object.keys(useAppSelector((state) => state.shopSettings.list)).length > 0;

  const [themeMode, setThemeMode] = useState<string>(
    LocalStorage.getThemeMode() || ThemeMode.lightMode
  );

  const [languageSettingsMode, setLanguageSettingsMode] = useState<string>(
    LocalStorage.getLanguageSettingsMode() || LanguageSettingsMode.english
  ); /**< Language settings mode */

  const didChangeTheme = (theme: ThemeMode) => {
    //Condition to prevent to change Shop Pages to dark mode due prefers-color-scheme logic
    if (
      window.location.pathname.includes(adminRoutes) ||
      window.location.pathname.includes(clientRoutes)
    ) {
      LocalStorage.setThemeMode(theme);
      setThemeMode(theme);

      if (
        !window.location.pathname.includes(SharedCommomRouteName.loginRoute)
      ) {
        if (
          LocalStorage.getXAuthToken() &&
          !isStringEmptyOrNullOrUndefined(LocalStorage.getXUserUUID())
        ) {
          dispatch(
            updateUserThemeModeStateAction({
              userUUID: LocalStorage.getXUserUUID(),
              themeMode: theme
            })
          ); /** Update user theme mode preference in database */
        }
      }
    }
  };

  /**
   * Change user language preference
   * @param language
   */
  const didChangeLanguage = (language: string) => {
    LocalStorage.setLanguageSettingsMode(language);
    setLanguageSettingsMode(language);

    if (!window.location.pathname.includes(SharedCommomRouteName.loginRoute)) {
      if (
        LocalStorage.getXAuthToken() &&
        !isStringEmptyOrNullOrUndefined(LocalStorage.getXUserUUID())
      ) {
        dispatch(
          updateUserLanguageSettingsModeStateAction({
            userUUID: LocalStorage.getXUserUUID(),
            languageSettingsMode: language
          })
        ); /** Update user language settings mode preference in database */
      }
    }
  };

  const renderRootPath = () => {
    //we can check on auState.isAuthenticated
    if (!LocalStorage.getXAuthToken()) {
      return <Redirect to={SharedCommomRouteName.validateRoute} />;
    }
    if (!isAppReconnecting) {
      if (authState.isAdmin) {
        return <Redirect to={adminRoutes} />;
      } else if (!authState.isAdmin) {
        return <Redirect to={clientRoutes} />;
      }
    } else {
      return <></>;
    }
  };

  const fnLogout = () => {
    customLocalStorageClearUtil();
    const parameters: URLSearchParams = new URLSearchParams({
      logout: 'true',
      themeMode: LocalStorage.getThemeMode(),
      languageSettingsMode: LocalStorage.getLanguageSettingsMode()
    }); /**< Query parameters */
    window.location.href = `${
      SharedCommomRouteName.validateRoute
    }?${parameters.toString()}`;
  };

  useEffect(() => {
    dispatch(getSafeSettings());
    dispatch(getAllTemplates());
    dispatch(fetchSettings());
    dispatch(reconnectAction());

    if (LocalStorage.getXAuthToken()) {
      dispatch(updateUserDetailsStateAction());
    }
  }, []);

  useEffect(() => {
    setAppReconnecting(authState.isReconnecting);
  }, [authState.isReconnecting]);

  useEffect(() => {
    if (shopSettings.customCssLink && isSettingsLoaded) {
      const cacheBuster = Date.now();
      load(
        `${BASE_API_URL}${shopSettings.customCssLink}/data?_=${cacheBuster}`,
        'customCss',
        true,
        false
      );
    }
  }, [shopSettings.customCssLink, templates.cssIsFetching]);

  useEffect(() => {
    const getImage = async () => {
      const resizedImage180 = await asyncErrorHandler(
        getFaviconURL(settings.setting, 180)
      );
      const resizedImage32 = await asyncErrorHandler(
        getFaviconURL(settings.setting, 32)
      );
      const resizedImage16 = await asyncErrorHandler(
        getFaviconURL(settings.setting, 16)
      );

      setFavouriteIconX180(resizedImage180);
      setFavouriteIconX32(resizedImage32);
      setFavouriteIconX16(resizedImage16);
    };
    getImage();
  }, [settings]);

  useEffect(() => {
    if (themeMode) {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener(
          'change',
          (e) => e.matches && didChangeTheme(ThemeMode.darkMode)
        );

      window
        .matchMedia('(prefers-color-scheme: light)')
        .addEventListener(
          'change',
          (e) => e.matches && didChangeTheme(ThemeMode.lightMode)
        );

      changeUserTheme(LocalStorage.getThemeMode());
    }
  }, [themeMode]);

  useEffect(() => {
    if (languageSettingsMode) {
      changeUserLanguage(languageSettingsMode, i18n);
    }
  }, [languageSettingsMode]);

  /// handle error when updating user theme mode state
  useEffect(() => {
    if (userAPIState.updateThemeModeStateError) {
      showToast({
        severity: 'error',
        summary: translation('userAPI.title_update_theme_mode_failed_toast'),
        detail: translation('userAPI.content_update_theme_mode_failed_toast')
      });
    }
  }, [userAPIState.updateThemeModeStateError]);

  /// handle error when updating user language settings mode state
  useEffect(() => {
    if (userAPIState.updateLanguageSettingsModeStateError) {
      showToast({
        severity: 'error',
        summary: translation(
          'userAPI.title_update_language_settings_mode_failed_toast'
        ),
        detail: translation(
          'I18N.error_messages.content_update_language_settings_mode_failed_toast'
        )
      });
    }
  }, [userAPIState.updateLanguageSettingsModeStateError]);

  // Listen to changes in localStorage.
  // HttpClient.ts file in AWC will fire a storage event when token is rewened to update theme-mode and language-settings-mode.
  // AuthenticationVerificationPage.tsx file will fire a storage event when redirect logic is applied to get theme mode and language settings mode from another application.
  window.addEventListener('storage', (event: StorageEvent) => {
    // Only triggers here when token is refresh in AWC/Client/HttpClient.ts
    if (event.key && event.key === LocalStorage.themeModeKey) {
      setThemeMode((prevState: string) => {
        prevState = event.newValue!;
        return prevState;
      });
      LocalStorage.setThemeMode(event.newValue!);
    } else if (
      event.key &&
      event.key === LocalStorage.languageSettingsModeKey
    ) {
      setLanguageSettingsMode((prevState: string) => {
        prevState = event.newValue!;
        return prevState;
      });
      LocalStorage.setLanguageSettingsMode(event.newValue!);
    } else {
      setThemeMode((prevState: string) => {
        prevState = LocalStorage.getThemeMode();
        return prevState;
      });
      setLanguageSettingsMode((prevState: string) => {
        prevState = LocalStorage.getLanguageSettingsMode();
        return prevState;
      });
    }
  });

  if (!shopSettings || Object.keys(shopSettings).length === 0)
    return <Loader />;

  return (
    <>
      {settings &&
        settings.setting &&
        Object.keys(settings.setting).length > 0 && (
          <AnalyticsProvider instance={analytics}>
            <main className="d-flex flex-column h-100 d-flex">
              <ErrorHandler />

              <AlertToast />
              <Header
                faviconX180={favouriteIconX180}
                faviconX32={favouriteIconX32}
                faviconX16={favouriteIconX16}
                applicationTitle={settings && settings.setting.applicationTitle}
              ></Header>
              <Suspense fallback={<></>}>
                <Router>
                  <ScrollToTop />
                  <Switch>
                    <Route exact path={SharedMainRouteName.adminRoute}>
                      <Redirect
                        to={{ pathname: RouteName.adminDashboardRoute }}
                      />
                    </Route>
                    <Route exact path={SharedMainRouteName.clientRoute}>
                      <Redirect
                        to={{ pathname: RouteName.clientTransactionsRoute }}
                      />
                    </Route>
                    <PublicRoute
                      exact
                      path="/"
                      component={ShopPage}
                      languageSettingsMode={languageSettingsMode}
                      didChangeLanguage={didChangeLanguage}
                    />
                    <PublicRoute
                      exact
                      path={`${RouteName.transactionRoute}/:transactionId`}
                      component={TransactionSuccessPage}
                      languageSettingsMode={languageSettingsMode}
                      didChangeLanguage={didChangeLanguage}
                    />
                    <Route
                      exact
                      path={SharedCommomRouteName.loginRoute}
                      render={() => renderRootPath()}
                    />
                    <Route
                      path={SharedCommomRouteName.validateRoute}
                      component={AuthenticationVerificationPage}
                    />
                    <Route
                      path={SharedCommomRouteName.configurationSettingsRoute}
                    >
                      <>
                        <TopBar
                          languageSettingsMode={languageSettingsMode}
                          didChangeLanguage={didChangeLanguage}
                          i18nService={i18n}
                          isThemeModeVisible={false}
                        />
                        <ConfigurationSettingsPage />
                      </>
                    </Route>
                    <Route path={SharedCommomRouteName.code404Route}>
                      <NotFound />
                    </Route>
                    <PublicRoute
                      exact
                      path={`/:productSKU`}
                      component={ProductPage}
                      languageSettingsMode={languageSettingsMode}
                      didChangeLanguage={didChangeLanguage}
                    />
                    <PublicRoute
                      exact
                      path={`/:productSKU/buy`}
                      component={CheckoutPage}
                      languageSettingsMode={languageSettingsMode}
                      didChangeLanguage={didChangeLanguage}
                    />
                    <PublicRoute
                      exact
                      path={`/product/:productSKU`}
                      component={ProductPage}
                      languageSettingsMode={languageSettingsMode}
                      didChangeLanguage={didChangeLanguage}
                    />
                    <PublicRoute
                      exact
                      path={`/product/:productSKU/buy`}
                      component={CheckoutPage}
                      languageSettingsMode={languageSettingsMode}
                      didChangeLanguage={didChangeLanguage}
                    />
                    <Route path={adminRoutes}>
                      <AdminPanel
                        fnLogout={fnLogout}
                        themeMode={themeMode}
                        didChangeTheme={didChangeTheme}
                        languageSettingsMode={languageSettingsMode}
                        didChangeLanguage={didChangeLanguage}
                      />
                    </Route>
                    <Route path={clientRoutes}>
                      <ClientPanel
                        fnLogout={fnLogout}
                        themeMode={themeMode}
                        didChangeTheme={didChangeTheme}
                        languageSettingsMode={languageSettingsMode}
                        didChangeLanguage={didChangeLanguage}
                      />
                    </Route>
                    <Route>
                      <Redirect
                        to={{ pathname: SharedCommomRouteName.code404Route }}
                      />
                    </Route>
                  </Switch>
                </Router>
              </Suspense>
            </main>
          </AnalyticsProvider>
        )}
    </>
  );
};

export default Main;
