/**
 * Users.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Jo Santiago, 2020
 *
 * @file Users.ts
 * @author Jo Santiago
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../Config';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import {
  IUser,
} from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';

const httpClient = new HttpClient(BASE_API_URL, createLog);

export const getAuthenticatedUserInformation = async (token: string) => {
  return httpClient.post(`/users/authenticated`, { userToken: token });
};

/**
 * Create a new address from data. If addressUUID is set,
 * updates the existing address.
 * @param data Payload to send with the request.
 * @param addressUUID id of the address
 * @returns IAddress
 */
export const createOrUpdateAddressAPI = (
  data: {
    name: any;
    country: any;
    address: any;
    addressTwo: any;
    city: any;
    stateOrProvince: any;
    zipCode: any;
    userToken: string | null;
  },
  addressUUID: string
) => {
  if (addressUUID) {
    return httpClient.put(`/users/address/${addressUUID}/update`, data);
  } else {
    return httpClient.post(`/users/address`, data);
  }
};

/**
 * Get all users
 * @param payload
 * @returns
 */
export const getAllUsers = async (payload: Record<string, any>) => {
  const skip: number = 0;
  const limit: number = 0; // show all
  const filterValue: string = payload.filter ?? ''; /**< Filter Value. */
  const filter: Record<string, any>[] = [];

  if (filterValue !== '') {
    filter.push(
      {
        column: 'firstName',
        operator: 'LIKE',
        value: filterValue
      },
      {
        column: 'lastName',
        operator: 'LIKE',
        value: filterValue,
        orClause: true
      },
      {
        column: 'email',
        operator: 'LIKE',
        value: filterValue,
        orClause: true
      },
      {
        column: 'username',
        operator: 'LIKE',
        value: filterValue,
        orClause: true
      }
    );
  }

  const url: string = `/users/all?skip=${skip}&limit=${limit}&filter=${encodeURIComponent(
    JSON.stringify(filter)
  )}&searchTerm=${encodeURIComponent(
    payload?.searchTerm ? payload.searchTerm : ''
  )}`;
  return httpClient.get<IUser[]>(url);
};

/**
 * Update user language preference
 * @returns Promise<IAPIEntityResponse<IUser>>
 */
export const updatedUserDetailsPingingUserAPI = (
): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.put(`/user/update/userDetails`, {});
};