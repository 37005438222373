/**
* TransactionTableExpansion.tsx (AbstractECommerce) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file TransactionTableExpansion.tsx
* @author Pascal Mayr
* @copyright 2020 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { CustomerDetailsTable } from '../../Common/CustomerDetailsTable';
import { CustomerBillingDetailsTable } from '../../Common/CustomerBillingDetailsTable';
import CustomerBillingDetailsForm from '../../Common/CustomerBillingDetailsForm';
import ProductTable from '../../Shop/TransactionSuccess/ProductTable';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/dracula.css';
import 'codemirror/mode/javascript/javascript.js';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { ICustomerHistory } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Customer';
import { Plan } from 'braintree';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { getProductSubscription } from '../../../Services/Product';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { IOption } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../Hooks';
import { fetchSettings } from '../../../Store/ShopSettings';
import {
  getProductOptionsDescription,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IProductTranslation } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/ProductTranslation';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { IFailureItems } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/FailureItems';
import { IApplication as EcommerceApplication } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Application';
import { ISettings } from '@abstract/abstractwebcommon-shared/interfaces/logoFavSetting';

/**
 * Interface for TransactionTableExpansion properties.
 */
interface ITransactionTableExpansionProperties {
  rowData: {
    customer?: any;
    payment?: any;
    products?: any;
    priceCalculation?: any;
    _id?: any;
    transactionFulfilled?: any;
    failureReasonID: IFailureItems;
    invoiceLink: string /**< Defines the link to access the invoice PDF */;
    [key: string]: any /**< Dynamic properties */;
  };
  regenerateInvoiceHandler: any;
  markLicenseCreatedHandler: any;
  licenseUpdateLoading: boolean;
  isAdminView: boolean /**< Is Admin view */;
  billingDetailsUpdateHandler: (
    id: string,
    payload: Record<string, any>
  ) => Promise<void> /**< Handler for updating billing details */;
}

const TransactionTableExpansion = ({
  rowData,
  regenerateInvoiceHandler,
  markLicenseCreatedHandler,
  licenseUpdateLoading,
  isAdminView,
  billingDetailsUpdateHandler
}: ITransactionTableExpansionProperties) => {
  const translation = useTranslation().t;
  const dispatch: Dispatch<any> = useDispatch();
  const customer = rowData.customer[0] ?? rowData.customer;
  const braintreeResponse = rowData.payment;
  const [isEdited, setEdited] = useState(false);
  const [billingInfo, setBillingInfo] = useState<ICustomerHistory>(customer);

  const [productSubscription, setProductSubscription] = useState<
    Plan
  >(); /**< Product subscription */
  const [
    isLoadingProductSubscription,
    setLoadingProductSubscription
  ] = useState<boolean>(true); /**< Loading state */
  const [languageSettingsMode, setLanguageSettingsMode] = useState<string>(
    LocalStorage.getLanguageSettingsMode() || LanguageSettingsMode.english
  ); /**< Language settings mode */

  const openTransactionSummaryPage = () => {
    window.sessionStorage.setItem('transactionEmail', customer.email);
    window.open(`/transaction/${rowData._id}`);
  };

  const handleBillingInformationUpdate = (payload) => {
    setBillingInfo({ ...customer, ...payload });
    setEdited(false);
    billingDetailsUpdateHandler(rowData._id, payload);
  };

  const [checked, setChecked] = useState(false);
  const [licenseChecked, setLicenseChecked] = useState(false);
  const shopSettings: IShopSettings = useAppSelector(
    (state) => state.shopSettings.list
  ); /**< Shop settings */
  const [formattedProductOptions, setFormattedProductOptions] = useState<
    IOption[]
  >([]); /**< Add description to product options array */
  const [productTranslation, setProductTranslation] = useState<
    IProductTranslation[]
  >([]); /**< Product translation data */
  const settings: ISettings = useAppSelector(
    (state) => state.settings
  ); /**< Settings */
  const [manageLicenseURL, setManageLicenseURL] = useState<string>(
    ''
  ); /**< URL to see license page */

  useEffect(() => {
    setLanguageSettingsMode(LocalStorage.getLanguageSettingsMode());
  }, [LocalStorage.getLanguageSettingsMode()]);

  const codeMirrorOptions = {
    theme: 'dracula',
    height: 'auto',
    viewportMargin: Infinity,
    mode: {
      name: 'javascript',
      json: true,
      statementIndent: 2
    },
    lineNumbers: true,
    lineWrapping: true,
    indentWithTabs: false,
    tabSize: 2
  };

  /**
   * Get product subscription data
   */
  const getProductSubscriptionData = async (): Promise<void> => {
    if (
      rowData.products[0].product &&
      rowData.products[0].product.subscriptionPlanID
    ) {
      // If there is no subscription data in product(if product from transaction object), fetch it from backend using product subscriptionPlanID.
      if (!rowData.products[0].product?.subscriptionPlan) {
        const subscription: IAPIEntityResponse<Plan> = await asyncErrorHandler(
          getProductSubscription(rowData.products[0].product.subscriptionPlanID)
        ); // Get product subscription

        if (subscription && subscription.subscriptionPlan) {
          setProductSubscription(subscription.subscriptionPlan);
        }
      } else {
        setProductSubscription(rowData.products[0].product?.subscriptionPlan);
      }
    }
    setLoadingProductSubscription(false);
  };

  useEffect(() => {
    getProductSubscriptionData();
    setProductTranslation(rowData?.products[0]?.product?.translationData);

    const productApplication: EcommerceApplication =
      rowData?.products[0]?.product?.application;

    if (
      productApplication &&
      !isStringEmptyOrNullOrUndefined(productApplication.applicationPageURL)
    ) {
      setManageLicenseURL(productApplication.applicationPageURL as string);
    }
  }, [rowData]);

  /**
   * Get product options
   */
  const getProductOptions = async () => {
    const allOptions: IOption[] = await asyncErrorHandler(
      getProductOptionsDescription(
        rowData.products[0],
        shopSettings,
        languageSettingsMode,
        productTranslation
      )
    );
    setFormattedProductOptions(allOptions);
  };

  useEffect(() => {
    getProductOptions();
  }, [languageSettingsMode, productTranslation]);

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  return (
    <>
      {' '}
      {isLoadingProductSubscription ? (
        <Loader />
      ) : (
        <div className="transactions-table-expansion">
          {!rowData.transactionFulfilled && isAdminView ? (
            <div className="panel panel-danger card bg-transparent mb-2 mt-2 border-0">
              <div className="panel-body card-body d-flex align-items-center justify-content-center flex-column">
                <p>{translation('transaction.no_license_created')}</p>

                <div className="p-col-6">
                  <Checkbox
                    inputId="cb1"
                    onChange={(e) => {
                      setLicenseChecked(e.target.checked);
                    }}
                    checked={licenseChecked}
                  />
                  <label htmlFor="cb1" className="p-checkbox-label">
                    {translation('transaction.license_created')}
                  </label>
                </div>
                <div className="p-col-6">
                  {!licenseUpdateLoading && (
                    <Button
                      className="d-flex align-items-center mr-3 ml-3"
                      variant="primary"
                      disabled={!licenseChecked}
                      onClick={markLicenseCreatedHandler.bind(this, rowData)}
                    >
                      <i className="bi bi-check2-circle btn-icon"></i>
                      {translation('transaction.update')}
                    </Button>
                  )}

                  {licenseUpdateLoading && <Loader />}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <Fieldset legend="Summary" className="text-left mb-2">
            <ProductTable
              products={[
                {
                  options: formattedProductOptions,
                  product: rowData.products[0].product,
                  quantity: rowData.products[0].quantity,
                  _id: rowData.products[0]._id,
                  productRef: {
                    name: rowData.products[0]?.productName
                  }
                }
              ]}
              priceCalculation={rowData.priceCalculation}
              className="admin-customer-table"
              productSubscription={productSubscription}
              languageSettingsMode={languageSettingsMode}
              productTranslation={productTranslation}
              transactedSubscription={rowData?.subscription}
              braintreeTransactionID={rowData?.braintreeTransactionID}
            />
            {rowData.customFields &&
            Object.keys(rowData.customFields).length ? (
              <Row>
                <div>
                  <table className="table">
                    <tbody>
                      {Object.keys(rowData.customFields).map(
                        (customFieldKey: string, index: number) => {
                          return (
                            <tr key={index}>
                              <td className="text-left border-top-0">
                                <b>{customFieldKey}:</b>
                              </td>
                              <td className="text-left border-top-0">
                                {rowData.customFields[customFieldKey]}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </Row>
            ) : (
              <></>
            )}

            <Row className="justify-content-end">
              {!isAdminView &&
              !isStringEmptyOrNullOrUndefined(manageLicenseURL) ? (
                <Button
                  className="d-flex align-items-center float-right mr-3 ml-3 mb-2"
                  variant="primary"
                  onClick={() => window.open(manageLicenseURL)}
                >
                  {translation('transaction.manage_license')}
                </Button>
              ) : (
                <></>
              )}
              {rowData.history && rowData.history.length > 0 ? (
                <>
                  <Button
                    className="d-flex align-items-center float-right mr-3 ml-3 mb-2"
                    variant="primary"
                    onClick={() => window.open(rowData?.invoiceLink)}
                  >
                    {translation('transaction.original_invoice')}
                  </Button>
                  <Button
                    className="d-flex align-items-center float-right mr-3 ml-3 mb-2"
                    variant="primary"
                    onClick={() =>
                      window.open(`${rowData.invoiceLink}?isEditedInvoice=true`)
                    }
                  >
                    {translation('transaction.view_edited_invoice')}
                  </Button>
                </>
              ) : (
                <>
                  {rowData.isRetry ? (
                    <></>
                  ) : (
                    <Button
                      className="d-flex align-items-center float-right mr-3 ml-3 mb-2"
                      variant="primary"
                      onClick={() => window.open(rowData?.invoiceLink)}
                    >
                      {translation('transaction.view_invoice')}
                    </Button>
                  )}
                </>
              )}

              {rowData.failureReasonID ? (
                <></>
              ) : (
                <Button
                  className="d-flex align-items-center float-right mr-3 ml-3 mb-2"
                  variant="primary"
                  onClick={openTransactionSummaryPage}
                >
                  {translation('transaction.view_summary')}
                </Button>
              )}
            </Row>
          </Fieldset>
          <Row className="d-block d-xl-flex">
            <Col>
              <Fieldset
                legend={translation(
                  'transactionSuccessPage.customerData.header'
                )}
                className="text-left mb-2"
              >
                <CustomerDetailsTable
                  customer={billingInfo}
                  className="admin-customer-table"
                />
              </Fieldset>
            </Col>
            {customer.billingAddress1 ? (
              <Col>
                <Fieldset
                  legend={translation(
                    'transactionSuccessPage.billingData.header'
                  )}
                  className="text-left mb-2"
                >
                  {isEdited ? (
                    <CustomerBillingDetailsForm
                      customer={customer}
                      handleSubmit={handleBillingInformationUpdate}
                    />
                  ) : (
                    <>
                      {isAdminView ? (
                        <Button
                          className="position-absolute edit-note-button"
                          onClick={() => setEdited(true)}
                          variant="outline"
                        >
                          <i className="bi bi-pencil-square editIcon fa-lg"></i>
                        </Button>
                      ) : (
                        <></>
                      )}
                      <CustomerBillingDetailsTable
                        customer={billingInfo}
                        className="admin-customer-table"
                      />
                    </>
                  )}
                </Fieldset>
              </Col>
            ) : (
              <Col></Col>
            )}
          </Row>
          {isAdminView ? (
            <>
              {/* NOTE: Currently, we are not including the Braintree response in the endpoint data. This button won't be displayed. */}
              {braintreeResponse && (
                <Row className="justify-content-center">
                  <Col md={6} className="d-flex justify-content-center">
                    <Button
                      variant="primary"
                      className="d-flex align-items-center"
                      checked={checked}
                      onClick={() => setChecked(!checked)}
                    >
                      {checked
                        ? translation('transaction.hide_braintree')
                        : translation('transaction.show_braintree')}
                    </Button>
                  </Col>
                </Row>
              )}
              <Row>
                <Col className="text-left">
                  {checked && (
                    <CodeMirror
                      className="text-left"
                      value={JSON.stringify(braintreeResponse, null, 2)}
                      options={{
                        mode: { name: 'javascript', json: true },
                        ...codeMirrorOptions
                      }}
                      autoCursor={false}
                      readOnly
                    />
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default TransactionTableExpansion;
